.login-container{
    height: 92.7vh;
}
.login-main-container{
      height: 75%
}
.connect-container{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-between;
}
.oublie{
height: 80px;
 font-size: 25px!important;
 color:  #fff!important;
 border: 1px solid #ffc451 !important;
}
.connectez-vous{
    margin-top:2rem
}
.card{
 background-color:transparent!important;
}
.card-header{
   background-color:#e3e6e7!important;
}
.card-body{
   background-color: rgba(0, 0, 0, 0.5)!important;
}
.card-body label{
   color:#fff
}
 .card-body span{
   color:#e3e6e7!important
}
.login-btn{
   background-color:#caa33f;
   color:#232e62;
    fontSize: 20px;
   font-family: Sora;
}
/* Extra small devices (phones, 1024px and down) */
  /* @media only screen and (min-width: 360px) and (max-width: 704px){
  .login-main-container{
      height: 30%
}
.oublie{
 font-size: 18px!important;
}
.lock{
    margin-bottom:'0.5rem'!important;
}
#hero h1 {
    margin-top:90px!important;
  font-size: 30px!important;

}

/* @media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }

   body {
   overflow-y: visible; 
   overflow-x: visible;
}

} */

/* @media (max-width: 768px) {
  #hero {
    height: auto;
  }
   body {
   overflow-y: visible; 
   overflow-x: visible;
}
.ctn{
  display:flex;
  margin:0
}

  #hero h1 {
    margin-top:3rem;
    font-size: 20px;
    line-height: 36px;
    margin-bottom: 2rem
  }

  #hero h2 {
    font-size: 20px;
    line-height: 24px;
  }
  .copyright {
    font-size: 0.8rem !important;
}
} */

/* Extra small devices (phones, 1024px and down) */
  /* @media only screen and (min-width: 768px) and (max-width: 1024px){
   #hero {
    height: auto;
  }
   body {
   overflow-y: visible; 
   overflow-x: visible;
}
.ctn{
  display:flex;
  margin:0
}

  #hero h1 {
    margin-top:3rem;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 2rem
  }

  #hero h2 {
    font-size: 30px!important;
    line-height: 40px!important;
  }

 #footer .copyright{
  font-size:1.2rem !important
 }

} */



/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

/* device from 360px , 740px (phones 740px and down) */
  @media only screen and (min-width: 360px) and (max-width: 768px){
 .info-souscripteur{
    font-size: 1.1rem !important;
    font-family:'helvetica';
    font-weight:'bold'
 }
 .souscripteur-inner-container{
    padding:2rem 0!important
 }
 .info-souscripteur-box{
    padding:0 1rem !important;
 }
 .info-souscripteur-main{
    display:flex!important;
    flex-direction:column!important;
 }
 .info-souscripteur-inner-box{
    width:100% !important;
    margin: 0 !important;
 }
 .inner-box-input{
    font-size:1.2rem!important;
 }
 .numcli-inner-box{
    font-size:1.2rem!important;
    margin-bottom:0!important;

 }
 .numcli-input-box{
    font-size:1.2rem!important;
    width:100%!important
 }
 .recherche-btn{
    height:20% !important;
    font-size: 1rem !important;
 }
}

/* .login-container{
  margin:7%;
  padding:5%;
} */
/*--------------------------------------------------------------

# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

/* device from 360px , 740px (phones 740px and down) */
  @media only screen and (min-width: 360px) and (max-width: 768px){
  .login-container{
  margin:0!important;
  padding:0!important;
}
.login-inner{
  margin-top:1rem!important;
}
}
