.box-link{
    color:#fff!important;
    text-decoration:none!important;
}
.box-link:hover{
    color:#fb8b24!important;
    text-decoration:none!important;
}
.plate-form{
    color:#e5e7ebc2!important;
}

