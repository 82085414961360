/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/


.card{
 background-color:transparent!important;
}
.card-header{
   background-color:#e3e6e7!important;
}
.card-body{
   background-color: rgba(0, 0, 0, 0.3)!important;
}

/* device from 360px , 740px (phones 740px and down) */
  @media only screen and (min-width: 360px) and (max-width: 768px){
 .info-souscripteur{
    font-size: 1.1rem !important;
    font-family:'helvetica';
    font-weight:'bold'
 }
 .souscripteur-inner-container{
    padding:2rem 0!important
 }
 .info-souscripteur-box{
    padding:0 1rem !important;
 }
 .info-souscripteur-main{
    display:flex!important;
    flex-direction:column!important;
 }
 .info-souscripteur-inner-box{
    width:100% !important;
    margin: 0 !important;
 }
 .inner-box-input{
    font-size:1.2rem!important;
 }
 .numcli-inner-box{
    font-size:1.2rem!important;
    margin-bottom:0!important;

 }
 .numcli-input-box{
    font-size:1.2rem!important;
    width:100%!important
 }
 .recherche-btn{
    height:20% !important;
    font-size: 1rem !important;
    height: 3rem !important;
 }
}