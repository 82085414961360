#table-grid-container{
 height: '92.7vh';
 backgroundColor: 'gray';
 display: 'flex';
 justifyContent: 'center';
alignItems: 'center' ;
}
#table-container{
    height: '65vh' 
}
.tableGrid-container{
    margin-top: 4rem!important;
    width: min-content!important;
 }
@media only screen and (min-width: 822px) and (max-width: 1920px){
 .tableGrid-container{
    margin-top: 4rem!important;
    width: min-content!important;
 }
}
.card{
 background-color:transparent!important;
}
.card-header{
   background-color:#e3e6e7!important;
}
.card-body{
   background-color: rgba(0, 0, 0, 0.5)!important;
}
.card-body label{
   color:#fff
}
 .card-body span{
   color:#e3e6e7!important
}
.error-color{
   color:red!important
}

