.accordion {
    --bs-accordion-bg: transparent !important;
    --bs-accordion-btn-color: #ffffff !important;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    --bs-accordion-active-color: #0b1e5e !important;
}
.accordion-body {
    color: #ffffff !important;
    border: var(--bs-accordion-border-width) solid #fff !important;
}
.accordion-button::after {
    color: #ffffff !important;
}