/* # Disable aos animation delay on mobile devices
-------------------------------------------------------------- */
/* .input-tags{
    background-color: #0f7674!important;
    font-size: initial;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.checkbox-container {
   display: flex;
   align-items: center;
   gap: 0.5rem;
   font-size: 1.2rem;
 }
 
 input[type="checkbox"] {
   display: none;
 }
 
 input[type="checkbox"] + label:before {
   content: "";
   display: inline-block;
   width: 1.7rem;
   height: 1.7rem;
   margin-right: 0.5rem;
   border-radius: 0.2rem;
   border: 1px solid #ccc;
   background-color: #fff;
 }
 
 input[type="checkbox"]:checked + label:before {
   content: "✔";
   display: inline-block;
   text-align: center;
   font-size: 1.2rem;
   font-weight: bold;
   border-color: #fff;
   background-color: #fff;
   color: #fff;
 }


.badge {
    display: flex;
    font-size: 1.1rem!important;
    font-weight:light!important;
    line-height: 1;
    justify-content:center;
    align-center:center;

}
.bg-secondary {
    --bs-bg-opacity: 1;
    background-color:#036ffc!important;
}

.pe-1 {
    padding-right: 0.5rem!important;
}
.me-1 {
    margin-right: 0.5rem!important;
}
.card{
 background-color:transparent!important;
}
.card-header{
   background-color:#e3e6e7!important;
}
.card-body{
   background-color: rgba(0, 0, 0, 0.5)!important;
}
.card-body label{
   color:#fff
}
 .card-body span{
   color:#e3e6e7!important
}
.error-color{
   color:red!important
}
.box-link{
    color:#fff!important;
    text-decoration:none!important;
}
.box-link:hover{
    color:#a4c27a!important;
    text-decoration:none!important;
}
.return-btn{
   background-color:#e3e6e7;
   color:#232e62;
    fontSize: 20px;
   font-family: Sora;
}
.section-title{
    color:#bdf3716b!important;
    padding-bottom:0.4rem;
}
.return-btn:hover{
    background-color: #65a8046b;
    color:#fff
}
.login-btn{
   background-color:#52860a6b;
   color:#232e62;
    fontSize: 20px;
   font-family: Sora;
}
/* device from 360px , 740px (phones 740px and down) */
  @media only screen and (min-width: 360px) and (max-width: 768px){
 .lightest-container{
    padding-top:1rem!important;
 }
 .lightest-inner{
    padding: 0!important;
    padding-right: 2px!important;
    padding-left: 1px!important;
 }
 .lightest-shadow{
    padding:0!important;
 }
 .renseignement{
    padding:1rem 0.5rem!important;
    font-size: 1.1rem!important
 }
 .lightest-main{
    padding: 0.75rem 1rem!important
 }
 /* .cutom-box{
    flex-direction:column!important;
    display:flex!important
 } */
 .welcome-container {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
 .custom-with{
    width:100%!important;
    font-size:1rem!important;
    font-family:'helvetica'!important
 }
 .custom-with-success{
    width:100%!important;
    font-size:1.6rem!important;
    font-family:'helvetica'!important;
    padding:2rem
 }
 /* .custom-with-label{
    font-size:1.3rem!important;
 } */
 .btn-container{
    flex-direction:row!important;
    width:100%!important;
 }
 .btn-container-input{
    font-size:1rem!important
 }
 /* #hero{
    height:100vh!important
 } */
}