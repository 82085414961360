.login-container{
    height: 92.7vh;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }

   body {
   overflow-y: visible; 
   overflow-x: visible;
}

}

@media (max-width: 768px) {
   body {
   overflow-y: visible; 
   overflow-x: visible;
}
.ctn{
  display:flex;
  margin:0
}

  #hero h1 {
    /*margin-top:3rem;*/
    font-size: 20px;
    line-height: 36px;
    /*margin-bottom: 2rem*/
  }

}
 /* @media only screen and (min-width: 360px) and (max-width: 704px){

#hero h1 {
  font-size: 25px!important;

}
.acceuil{
  margin-top: 4rem;
}

} */

/* Extra small devices (phones, 1024px and down) */
  /* @media only screen and (min-width: 768px) and (max-width: 1024px){
   #hero {
    height: auto;
  }
   body {
   overflow-y: visible;
   overflow-x: visible;
}
.ctn{
  display:flex;
  margin:0
}

  #hero h1 {
    margin-top:3rem;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 2rem
  }

} */
@media only screen and (min-width: 822px) and (max-width: 1920px){
 .tableGrid-container{
    margin-top: 4rem!important;
    width: min-content!important;
 }
}
