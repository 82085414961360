.login-container{
    height: 92.7vh;
    opacity: 0.5;
    background-image: url("https://www.nsiavieassurances.ci/files/images/slide/30d2d19c-c4f1-4469-b3ee-54282cb8e8b2.jpeg");
}
/*--------------------------------------------------------------
# signup-hero Section
--------------------------------------------------------------*/
.acceuil{
  margin-bottom: 2rem;
}
#signup-hero {
  width: 100%;
  height: 92.7vh;
  background-size: cover;
  position: relative;
}

#signup-hero:before {
  content: "";
  background: transparent!important;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#signup-hero:after {
  background: transparent!important;
}

#signup-hero .container {
  position: relative;
  /* padding-top: 74px; */
  text-align: center;
  
}

#signup-hero h1 {
  margin: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: "Sora", sans-serif;
}

#signup-hero h1 span {
  color: #ffc451;
}

#signup-hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 50px;
}

#signup-hero .icon-box {
   padding: 20px 50px;
    transition: ease-in-out 0.3s;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    text-align: center;
    width: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#signup-hero .icon-box i {
  font-size: 80px;
  line-height: 1;
  color: #ffc451;
}

#signup-hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 30px;
  line-height: 26px;
}

#signup-hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}

#signup-hero .icon-box h3 a:hover {
  color: #ffc451;
}

#signup-hero .icon-box:hover {
  border-color: #ffc451;
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}
/* @media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
} */
ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}
ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #CCCCCC;
}
ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #0d6efd;
}
ol.progtrckr li:after {
  content: "\00a0\00a0";
}
ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}
ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}
ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}
ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #CCCCCC;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}
ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #0d6efd;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}
.signup-container{
  /* margin-top:2%; */
  padding:3% 5%;
}
/*--------------------------------------------------------------

# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

/* device from 360px , 740px (phones 740px and down) */
  @media only screen and (min-width: 360px) and (max-width: 768px){
  .signup-container{
  margin:0!important;
  padding:0!important;
}
.signup-inner{
  margin-top:1rem!important;
}
}