@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
.timeline {
    width: auto;
    color: #fff;
}
.timeline ul {
    list-style-type: none;
    border-left: 5px solid #1e2d4dff;
    padding: 10px 5px;
}
.timeline ul li {
    padding: 20px;
    position: relative;
    cursor: pointer;
    transition: .5s;
    text-align: left;
    background-color: #ffffff;
    margin: 10px 5px 10px 20px;
    border-radius: 5px;
}
.timeline ul li .arrow {
    border-right: 12px solid #fff !important;
    display: block;
    left: -12px;
    width: 0;
    border-bottom: 12px solid transparent;
    border-top: 12px solid transparent;
    height: 0;
    margin-top: -4px;
    position: absolute;
    top: 20%;
    color: transparent;
}
.timeline ul li span {
    display: inline-block;
    background-color: #1e2d4dff;
    border-radius: 25px;
    padding: 2px 10px;
    font-size: 15px;
    text-align: center;
}
.timeline ul li .content h3 {
    color: #65a804ff;
    font-size: 17px;
    padding-top: 5px;
    font-weight: bold;
}
.timeline ul li .content p {
    padding: 5px 0;
    font-size: 15px;
    color: #000000
}
.timeline ul li:before {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    background-color: #65a804ff;
    border-radius: 50%;
    left: -35px;
    top: 20%;
    transition: .5s;
}
.timeline ul li:hover {
    background-color: #65a804ff;
}
.timeline ul li:hover .content h3 {
    color: #ffffff;
}
.timeline ul li:hover:before {
    background-color: #0F0;
    box-shadow: 0 0 10px 2px #0F0;
}

@media (max-width:300px) {
    .timeline {
        width: 100%;
        padding: 30px 5px 30px 10px;
    }
    .timeline ul li .content h3 {
        color: #34ace0;
        font-size: 15px;
    }
}

@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}