
.fixed-element {
    position: fixed;
    top: 0;
    margin-top:5rem;
    left: 0;
    bottom: 0;
    z-index: 1;
    overflow-y: auto;
    scrollbar-width: none !important;
}

.fixed-element::-webkit-scrollbar {
    width: 0 !important;
    background-color: transparent !important;
}

.scrollable-element {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
}

.horizontal-timeline .items {
    border-top: 3px solid #0b1e5e;
    margin-top: 15px;
}

.horizontal-timeline .items .items-list {
    display: inline-block;
    position: relative;
    text-align: center;
    padding-top: 5px;
    margin-right: 0;
    width: 33.33%;
}

.horizontal-timeline .items .items-list:before {
    content: "";
    position: absolute;
    height: 35px;
    border-right: 2px dashed #0b1e5e;
    top: 0;
}

.horizontal-timeline .items .items-list .event-date {
    position: relative;
    top: -42px;
    text-align: center;
    width: auto;
    /*margin: 1px;*/
    font-size: 0.9rem !important;
    padding: 8px 4px;
}