.error-color{
   color:red!important
}
.login-btn{
   background-color:#8ed8286b;
   color:#232e62;
    fontSize: 20px;
   font-family: Sora;
}
.login-card-body{
   padding:1rem;
} 
/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/


/* device from 360px , 740px (phones 740px and down) */
  @media only screen and (min-width: 360px) and (max-width: 768px){
 .lightest-container{
    padding-top:1rem!important;
 }
 .lightest-inner{
    padding: 0!important;
    padding-right: 2px!important;
    padding-left: 1px!important;
 }
 .lightest-shadow{
    padding:0!important;
 }
 .renseignement{
    padding:1rem 0.5rem!important;
    font-size: 1.1rem!important
 }
 .lightest-main-login{
    padding: 0 0.5rem!important
 }
 /* .cutom-box{
    flex-direction:column!important;
    display:flex!important
 } */
 .custom-with-code{
    margin-left:0!important;
 }
 .code-box{
    width:100%!important;
    margin-top:2rem!important;
 }
 .custom-with{
    width:100%!important;
    font-size:1rem!important;
    font-family:'helvetica'!important
 }
 .custom-with-success{
    width:100%!important;
    font-size:1.6rem!important;
    font-family:'helvetica'!important;
    padding:2rem
 }
 /* .custom-with-label{
    font-size:1.3rem!important;
 } */
 .btn-container{
    flex-direction:row!important;
    width:100%!important;
 }
 .btn-container-input{
    font-size:1rem!important
 }
 .mode-pass{
    font-size:0.8rem!important;
 }
 .mdp-btn{
    width:100%!important;
 }

}