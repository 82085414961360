.img-design{
    height: 25%;
    width: 25%;
    display:inline-block!important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}
.btn-custom {
  --bs-btn-color: #000;
  --bs-btn-bg: #65a804!important;
  --bs-btn-border-color: #65a804!important;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #65a804!important;
  --bs-btn-hover-border-color: #65a804!important;
  --bs-btn-focus-shadow-rgb: 217,164,6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #65a804!important;
  --bs-btn-active-border-color: #65a804;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #65a804!important;
  --bs-btn-disabled-border-color: #65a804!important;
}


/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  background-color:  #1e2d4d!important;
  border-bottom: 5px solid #65a8046b;
  justify-content: center;
  display: flex;
  align-items: center;
}

#header.header-scrolled,
#header.header-inner-pages {
   background: rgba(0, 0, 0, 0.8);
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo a span {
  color: #ffc451;
}

#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

@media screen and (min-width: 1280px) {
    .img-design {
        height: 25%;
        width: 25%!important;
    }
}

@media only screen and (min-width: 822px) and (max-width: 1920px){
   .img-design {
        height: 25%;
        /*width: 13%!important;*/
    }
    #hero h2 {
        font-size: 48px!important;
    }
}



